import React, { useEffect } from "react";
import { redirectExternalUrl } from "../../shared/utils/redirect-to-external";

export const LogoutCallback: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams.get("state") as string;

    useEffect(() => {
        const uriDecodedState = decodeURI(state);
        const decodedReturnUrl = atob(uriDecodedState);
        redirectExternalUrl(decodedReturnUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <></>
    );
};

export default LogoutCallback;