import { useSetRecoilState } from "recoil";
import { clientState, clientKeyState, redirectUrlState, usernameState } from "shared/state";
import { ClientType } from "models/ClientType";
import { mapQueryParameterToClientType } from "../../shared/utils/map-query-to-client-type";
import { redirectToHubUrl } from "../../shared/utils";

export const parseQueryParam = (paramName: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(paramName);
};

export const useParseClientQueryParam = () => {
    const setClientStateState = useSetRecoilState(clientState);
    const action = () => {
        const clientType = mapQueryParameterToClientType();
        setClientStateState(clientType);
    };

    return action;
};

export const useParseClientKeyQueryParam = () => {
    const setClientKeyStateState = useSetRecoilState(clientKeyState);

    const action = () => {
        const newClientKey = parseQueryParam("clientKey");
        if (newClientKey != null) {
            setClientKeyStateState(newClientKey);
        }
    };

    return action;
};

export const useParseUsernameQueryParam = () => {
    const setUsernameStateState = useSetRecoilState(usernameState);

    const action = () => {
        const newUsername = parseQueryParam("username");
        if (newUsername != null) {
            setUsernameStateState(newUsername);
        }
    };

    return action;
};

export const useParseRedirectUrlQueryParam = () => {
    const setRedirectUrlStateState = useSetRecoilState(redirectUrlState);

    const action = () => {
        const newRedirectUrl = parseQueryParam("redirectUrl");
        if (newRedirectUrl != null) {
            setRedirectUrlStateState(newRedirectUrl);
        }
    };

    return action;
};

export const useRedirectToHubIfMissingQueryParams = () => {
    const action = () => {
        const queryParams = new URLSearchParams(window.location.search);
        if (!queryParams.has("redirectUrl") && mapQueryParameterToClientType() === ClientType.web) {
            redirectToHubUrl();
        }
    };

    return action;
};
