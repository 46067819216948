import React, { useCallback, useEffect } from "react";
import { Result } from "@iqmetrix/antd";
import type { InteropBodyRequest } from "@iqmetrix/host-interop";
import { SetterOrUpdater, useRecoilState } from "recoil";
import { atom } from "recoil";
import { useLocalizedMessage } from "../../hooks";
import { InteropRequestBody } from "models/InteropRequestBody";
import { ClientType } from "models";
import { mapQueryParameterToClientType } from "../../shared/utils";
import uuid from "react-uuid";

export const Callback: React.FC = () => {
    const [isError, setErrorState] = useRecoilState(errorState);

    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get("accessToken") || "";
    const refreshToken = queryParams.get("refreshToken") || "";

    const callHook = useCallback(() => {
        if (accessToken != "" && refreshToken != "") {
            const userId = queryParams.get("userId") || "";
            const clientType = mapQueryParameterToClientType();

            const requestBody: InteropRequestBody = {
                token: accessToken,
                refreshToken: refreshToken,
                userId: userId,
            };

            const request = {
                body: requestBody,
                id: uuid(),
                name: "tokenAndUserId",
                type: "request",
                version: "1",
            } as InteropBodyRequest<InteropRequestBody>;

            if (clientType === ClientType.mobile) {
                sendMessageForMobile(request, setErrorState);
            } else {
                sendMessageForDesktopAndDefault(request, clientType, setErrorState);
            }
        } else {
            setErrorState(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        callHook();
    }, [callHook]);

    return <>{isError === false ? <></> : <TokenNotProvidedResult />}</>;
};

const TokenNotProvidedResult: React.FC = () => {
    const localizedErrorTitle = useLocalizedMessage("Callback.Error_title");
    const localizedErrorResponse = useLocalizedMessage("Callback.No_access_token_was_provided");
    return <Result title={localizedErrorTitle} response={{ status: 900, statusText: localizedErrorResponse }} />;
};

export async function sendMessageForDesktopAndDefault(request: InteropBodyRequest<InteropRequestBody>, clientType: ClientType, setErrorState: SetterOrUpdater<boolean>) {
    const interopTarget = getInteropTarget();

    try {
        interopTarget.postMessage(request);
    } catch (error) {
        setErrorState(true);
    }
}

export const errorState = atom({
    key: "isError",
    default: false,
});

export const sendMessageForMobile = (request: InteropBodyRequest<InteropRequestBody>, setErrorState: SetterOrUpdater<boolean>) => {
    if (window && (window as any).ReactNativeWebView) {
        sendReactNativeMessage(request, setErrorState);
    } else {
        sendDefaultMobileMessage(request, setErrorState);
    }
};

const sendReactNativeMessage = (requestBody: InteropBodyRequest<InteropRequestBody>, setErrorState: SetterOrUpdater<boolean>) => {
    try {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(requestBody));
    } catch (error) {
        setErrorState(true);
    }
};

const sendDefaultMobileMessage = (requestBody: InteropBodyRequest<InteropRequestBody>, setErrorState: SetterOrUpdater<boolean>) => {
    try {
        window.postMessage(requestBody);
    } catch (error) {
        setErrorState(true);
    }
};

const getInteropTarget = () => {
    const cefSharpTarget = (globalThis as any).CefSharp;
    if (cefSharpTarget) cefSharpTarget.postMessage = (msg: any) => cefSharpTarget.PostMessage(msg);
    return cefSharpTarget ?? window;
};

export default Callback;
