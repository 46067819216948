const messages = {
    PageTitle: "Ingresar a iQmetrix",
    CovaPageTitle: "Ingresar a COVA",

    "Login.Next": "Siguiente",
    "Login.Sign_in_Title": "Ingresar",
    "Login.Username_or_Email": "Nombre de Usuario o Correo Electrónico",

    "ForgotPassword.Cancel": "Cancelar",
    "ForgotPassword.Email_address": "Correo Electrónico",

    "ForgotPassword.Instructions.Please_enter_the_email_address":
        "Por favor escriba la dirección de correo electrónico asociada a su cuenta y le enviaremos instrucciones para configurar su contraseña nuevamente",
    "ForgotPassword.Instructions.For_further_assistance": "Para obtener más ayuda, por favor contacte con su Administrador de Hub",

    "ForgotPassword.Alert_message": "Si es que su cuenta existe, recibirá un correo electrónico con su contraseña temporal.",
    "ForgotPassword.Reset_Password_Button": "Recuperar Contraseña",
    "ForgotPassword.Reset_password_Title": "Recuperar Contraseña",

    "Credentials.Error.The_credentials_you_have_entered_are_incorrect": "Los datos de usuario que ingreso son incorrectos.",
    "Credentials.Error.Please_try_again": "Por favor inténtelo de nuevo.",
    "Credentials.Error.UserLockedTitle": "Su cuenta ha sido bloqueada",
    "Credentials.Error.User_is_locked": "Por favor contacte al administrador para desbloquear su cuenta",

    "Credentials.Forgot_password": "¿Olvidó su contraseña?",
    "Credentials.Password": "Contraseña",
    "Credentials.Sign_in_button": "Registro",
    "Credentials.Sign_in_title": "Registro",
    "Credentials.Username_change": "Cambiar",

    "ChangePassword.Cancel": "Cancelar",
    "ChangePassword.Change_password_button": "Cambiar Contraseña",
    "ChangePassword.Change_password_title": "Cambiar Contraseña",
    "ChangePassword.Confirm_password": "Confirmar contraseña",
    "ChangePassword.Current_password": "Contraseña actual",
    "ChangePassword.New_password": "Nueva contraseña",
    "ChangePassword.Your_current_password_is_temporary": "Su contraseña actual es temporal y debe ser cambiada.",
    "ChangePassword.Your_password_was_reset": "Su contraseña ha sido actualizada. Ingrese a su cuenta usando su nueva contraseña.",
    "ChangePassword.Your_passwords_must_match": "Las contraseñas deben ser iguales.",
    "ChangePassword.ChangePassword_IncorrectPassword": "La contraseña actual es incorrecta.",
    "ChangePassword.ChangePassword_EmptyNewPassword": "La contraseña no puede estar vacía.",
    "ChangePassword.ChangePassword_NewAndTempPasswordEquivalent": "La nueva contraseña no puede ser igual a la contraseña temporal.",
    "ChangePassword.ChangePassword_PasswordStrength": "La nueva contraseña debe tener al menos {} caracteres.",
    "ChangePassword.ChangePassword_PasswordMinimumRevisions": "La nueva contraseña debe ser diferente de al menos {} contraseñas anteriores.",
    "ChangePassword.ChangePassword_PasswordMinimumRevisions_Multiple": "La nueva contraseña debes ser diferente de su contraseña anterior",
    "ChangePassword.ChangePassword_PasswordComplexity": "La nueva contraseña debe tener al menos un caracter de {} de los siguientes: mayúscula, minúscula, números y caracteres especiales. ",
    "ChangePassword.ChangePassword_EmptyUsernameOrEmail": "Debe escribir un nombre de usuario o correo electrónico.",
    "ChangePassword.ChangePassword_EmptyCurrentPassword": "Debe escribir la contraseña.",
    "ChangePassword.ChangePassword_CurrentAndNewPasswordEquivalent": "La contraseña actual y la nueva no pueden ser iguales.",
    "ChangePassword.Server_error_alert": "Hubo un error con el servidor",

    "Callback.Error_title": "Hubo un error con el servidor",
    "Callback.No_access_token_was_provided": "No se ha proveído un accessToken. Por favor intente nuevamente o contacte a su administrador",
    "Error.Non_existent_external_user_error_title": "Su cuenta no ha sido configurada todavía. ",
    "Error.Non_existent_external_user_error_response": "Contactese con el administrador del sistema para crear una cuenta.",
    "Error.External_user_does_not_match_error_response": "Authenticated user does not match initiating user.",

    "Logout.Server_error_alert": "Hubo un error con el servidor",
    "Logout.Server_try_again": "Por favor intente nuevamente o contacte a su administrador",
};

export default messages;
