import * as React from "react";
import * as Sentry from "@sentry/react";
import { Result } from "@iqmetrix/antd";
import { FallbackRender } from "@sentry/react/dist/errorboundary";

interface FallBackProps {
    error: Error;
}

const FallbackComponent: React.FC<FallBackProps> = (props: FallBackProps) => {
    return <Result icon={null} response={props.error.message} />;
};

const myFallback: FallbackRender = (errorData) => <FallbackComponent error={errorData.error} />;

export const ErrorBoundary = ({ children }: React.PropsWithChildren<unknown>) => <Sentry.ErrorBoundary fallback={myFallback}>{children}</Sentry.ErrorBoundary>;
