import {
    getLoginConfigurationUrl,
    getCredentialLoginUrl,
    getChangePasswordUrl,
    getForceChangePasswordUrl,
    getForgotPasswordUrl,
    getLogoutUrl,
    getAuthRevokeCookieUrl,
    getLoginRedirectUrl
} from "shared/client/universallogin-client";
import { makeHttpCall, makeHttpCallWithResponse } from "../../shared/http/httpClient";
import { HttpRequest, HttpObjectResult, HttpResult, HttpErrorResult } from "models/http/http";
import {
    ChangePasswordRequest,
    ChangePasswordResource,
    ForceChangePasswordRequest,
    ForceChangePasswordResource,
    ForgotPasswordResource,
    ForgotPasswordRequest,
    LoginConfigurationResource,
    TokenAndUserIdResource,
    TokenAndUserIdResourceRequest,
    LogoutResource,
    LoginRedirectUrlResource
} from "models";

const AUTH_FORCE_CHANGE_PASSWORD_ERROR_CODE = "AuthForcePasswordChangeErrorCode";
const AUTH_USER_LOCKED_ERROR_CODE = "AuthUserLockedErrorCode";

const doAuthConfig = async (username: string, clientKey: string, redirectUrl: string): Promise<HttpObjectResult<LoginConfigurationResource>> => {
    const request = {
        url: getLoginConfigurationUrl(username, clientKey, redirectUrl),
        method: "GET",
    } as HttpRequest;
    return (await makeHttpCallWithResponse<LoginConfigurationResource>(request)) as HttpObjectResult<LoginConfigurationResource>;
};

const doCredentialLogin = async (usernameorEmail: string, password: string, clientKey: string, redirectUrl: string): Promise<HttpResult<TokenAndUserIdResource>> => {
    const tokenAndUserIdResourceRequest: TokenAndUserIdResourceRequest = {
        UsernameOrEmailAddress: usernameorEmail,
        Password: password,
        ClientKey: clientKey,
        RedirectUrl: redirectUrl,
    };
    const request = {
        url: getCredentialLoginUrl(),
        method: "POST",
        body: JSON.stringify(tokenAndUserIdResourceRequest),
    } as HttpRequest;

    return await makeHttpCallWithResponse<TokenAndUserIdResource>(request);
};

const doForceChangePassword = async (usernameOrEmail: string, currentPassword: string, newPassword: string, userId: number): Promise<HttpResult<ForceChangePasswordResource>> => {
    const forceChangePasswordRequest: ForceChangePasswordRequest = { usernameOrEmail, currentPassword, newPassword, userId };
    const request = {
        url: getForceChangePasswordUrl(),
        method: "POST",
        body: JSON.stringify(forceChangePasswordRequest),
    } as HttpRequest;

    return await makeHttpCallWithResponse<ForceChangePasswordResource>(request);
};

const doChangePassword = async (accessToken: string, currentPassword: string, newPassword: string): Promise<HttpResult<ChangePasswordResource>> => {
    const changePasswordRequest: ChangePasswordRequest = { currentPassword, newPassword };
    const request = {
        url: getChangePasswordUrl(),
        method: "POST",
        body: JSON.stringify(changePasswordRequest),
        accessToken,
    } as HttpRequest;
    return await makeHttpCallWithResponse<ChangePasswordResource>(request);
};

const doForgotPassword = async (email: string): Promise<HttpObjectResult<ForgotPasswordResource>> => {
    const forgotPasswordRequest: ForgotPasswordRequest = { email };
    const request = {
        url: getForgotPasswordUrl(),
        method: "POST",
        body: JSON.stringify(forgotPasswordRequest),
    } as HttpRequest;
    return (await makeHttpCall(request)) as HttpObjectResult<ForgotPasswordResource>;
};

const doLogout = async (accessToken: string, redirectUrl: string): Promise<HttpObjectResult<LogoutResource> | HttpErrorResult> => {
    const request = {
        url: getLogoutUrl(redirectUrl),
        method: "GET",
        accessToken,
    } as HttpRequest;

    return (await makeHttpCallWithResponse<LogoutResource>(request)) as HttpObjectResult<LogoutResource>;
};

const doRevokeCookie = async (): Promise<HttpObjectResult<unknown>> => {
    const request = {
        url: getAuthRevokeCookieUrl(),
        method: "POST",
        credentials: "include"
    } as HttpRequest;

    return (await makeHttpCall(request)) as HttpObjectResult<unknown>;
};

const doGetLoginRedirectUrl = async (returnUrl: string): Promise<HttpResult<LoginRedirectUrlResource>> => {
    const request = {
        url: getLoginRedirectUrl(returnUrl),
        method: "GET",
    } as HttpRequest;

    return await makeHttpCallWithResponse<LoginRedirectUrlResource>(request);
};

export { doAuthConfig, doCredentialLogin, doChangePassword, doForceChangePassword, doForgotPassword, doLogout, doRevokeCookie, doGetLoginRedirectUrl, AUTH_FORCE_CHANGE_PASSWORD_ERROR_CODE, AUTH_USER_LOCKED_ERROR_CODE };
