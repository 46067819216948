import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { doLogout } from "providers/auth-provider";
import { redirectExternalUrl, redirectToHubUrl } from "../../shared/utils/redirect-to-external";
import { logoutAlertMsgState } from "../../shared/state";
import { LogoutResource } from "../../models";
import { useLocalizedMessage } from "../../hooks";
import { StyledAlert } from "components/custom-styled-components/CustomStyledComponents";
import { HttpErrorResult, HttpObjectResult } from "models/http/http";

export const Logout: React.FC = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get("accessToken") as string;
    const redirectUrl = queryParams.get("redirectUrl") as string;
    const [logoutAlertMsg, setLogoutAlertMsg] = useRecoilState(logoutAlertMsgState);
    const LogoutErrorLine1 = useLocalizedMessage("Logout.Server_error_alert");
    const LogoutErrorLine2 = useLocalizedMessage("Logout.Server_try_again");

    const logout = async () => {
        const result = await doLogout(accessToken, redirectUrl);

        if (result.hasError) {
            const error = result as HttpErrorResult;
            if (error && error.statusCode === 401) {
                // Unauthorized, redirect to default log in page
                redirectToHubUrl();
            } else {
                setShowErrorMessage();
            }
        } else {
            setLogoutAlertMsg(null);
            redirectExternalUrl((result as HttpObjectResult<LogoutResource>).value.callbackUrl);
        }
    };

    const setShowErrorMessage = () => {
        setLogoutAlertMsg({
            type: "error",
            message: (
                <>
                    {LogoutErrorLine1}
                    <br />
                    {LogoutErrorLine2}
                </>
            ),
        });
    };

    useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{logoutAlertMsg && <StyledAlert type={logoutAlertMsg.type} message={logoutAlertMsg.message} data-testid="msgAlert" />}</>;
};

export default Logout;
