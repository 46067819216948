import React from "react";
import { Row } from "@iqmetrix/antd";
import { PaddingPageheaderVertical } from "@iqmetrix/style-tokens";

export const NonBorderLayout: React.FC = (props) => {
    return (
        <>
            <Row style={{marginTop: PaddingPageheaderVertical, width: "100%"}} justify="center" align="middle" className="center-page-content">
                { props.children }
            </Row>
        </>
    );
};
export default NonBorderLayout;