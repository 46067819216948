import React from "react";
import { Row } from "@iqmetrix/antd";
import SidePane from "../side-pane/SidePane";
import CentralPane from "../central-pane/CentralPane";
import TopBar from "../top-bar/TopBar";

export const PageLayout: React.FC = (props) => {
    return (
        <div data-testid="pageLayout">
            <TopBar>{window.location.hostname.toLowerCase().includes("cova") ? <img src="./cova_bar_logo.png" /> : <img src="./iq_bar_logo.png" />}</TopBar>
            <Row style={{ minHeight: "90vh" }} justify="center" align="middle" className="center-page-content">
                <SidePane />
                <CentralPane>{props.children}</CentralPane>
                <SidePane />
            </Row>
        </div>
    );
};
export default PageLayout;
