import { getEnvironmentSuffix } from "./get-environment";

const envSuffix = getEnvironmentSuffix();

const universalLoginApiRoot = `https://signinbackend${envSuffix}.iqmetrix.net`;

const authApiRoot = `https://accounts${envSuffix}.iqmetrix.net`;

export const getUniversalLoginV1Endpoint = () => {
    return `${universalLoginApiRoot}/v1`;
};

export const getAuthEndpoint = () => {
    return `${authApiRoot}`;
};