import React from "react";
import { Form, Input, Typography } from "@iqmetrix/antd";
import { RuleObject } from "antd/lib/form";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { doChangePassword, doForceChangePassword, doRevokeCookie, doGetLoginRedirectUrl } from "providers/auth-provider";
import { useHistory } from "react-router-dom";
import { showChangePasswordErrorState, clientState, credentialAlertMsgState, forceChangePasswordState, usernameState, redirectUrlState, clientKeyState, userIdState } from "../../shared/state";
import { useLocalizedMessage, useParseClientKeyQueryParam, useParseUsernameQueryParam } from "../../hooks";
import { redirectExternalUrl } from "../../shared/utils/redirect-to-external";
import { ViewModeHandler } from "../view-mode-handler";
import { StyledSpace, StyledForm, StyledButton, StyledRightAlignCol } from "components/custom-styled-components";
import { HttpErrorResult, HttpObjectResult } from "models/http/http";
import { ChangePasswordError } from "./";
import { ClientType, LoginRedirectUrlResource } from "models";

const { Text, Title } = Typography;

const currentPasswordState = atom({
    key: "currentPasswordState",
    default: "",
});

const newPasswordState = atom({
    key: "newPasswordState",
    default: "",
});

const confirmPasswordState = atom({
    key: "confirmPasswordState",
    default: "",
});

const changePasswordBtnLoadingState = atom<boolean>({
    key: "changePasswordBtnLoadingState",
    default: false,
});

export const ChangePassword: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("accessToken") as string;
    const clientKey = useRecoilValue(clientKeyState);

    const parseClientKeyQueryParam = useParseClientKeyQueryParam();
    const parseUsernameQueryParam = useParseUsernameQueryParam();
    parseClientKeyQueryParam();
    parseUsernameQueryParam();

    const history = useHistory();
    const [currentPassword, setCurrentPassword] = useRecoilState(currentPasswordState);
    const [newPassword, setNewPassword] = useRecoilState(newPasswordState);
    const [confirmPassword, setConfirmPassword] = useRecoilState(confirmPasswordState);
    const [changePasswordBtnIsLoading, setChangePasswordBtnLoading] = useRecoilState(changePasswordBtnLoadingState);
    const [username, setUsername] = useRecoilState(usernameState);
    const setCredentialAlertMsg = useSetRecoilState(credentialAlertMsgState);
    const client = useRecoilValue(clientState);
    const [forceChangePassword, setForceChangePasswordState] = useRecoilState(forceChangePasswordState);
    const setShowChangePasswordErrorState = useSetRecoilState(showChangePasswordErrorState);
    const redirectUrl = useRecoilValue(redirectUrlState);
    const [userId, setUserId] = useRecoilState(userIdState);

    const confirmPasswordMatch = (value?: string) => value && value.trim().length > 0 && value === newPassword;
    const isChangePasswordBtnEnabled = currentPassword && newPassword && confirmPassword && confirmPasswordMatch(confirmPassword);
    const isCancelBtnHidden = client != ClientType.web;
    const [form] = Form.useForm();

    const yourPasswordWasReset = useLocalizedMessage("ChangePassword.Your_password_was_reset");
    const yourPasswordsMustMatch = useLocalizedMessage("ChangePassword.Your_passwords_must_match");

    const redirectToSignIn = (username: string, loginRedirectUrl: string) => {
        setUsername(username);
        setCredentialAlertMsg({
            type: "success",
            message: yourPasswordWasReset,
        });

        history.push(`/credentials?redirectUrl=${loginRedirectUrl}&clientKey=${clientKey}&username=${username}&client=${client}`);
    };

    const onCancelButtonClicked = () => {
        redirectExternalUrl(redirectUrl);
    };

    const onChangePasswordClicked = async () => {
        setChangePasswordBtnLoading(true);

        const changePasswordResult = forceChangePassword
            ? await doForceChangePassword(username, currentPassword, newPassword, userId)
            : await doChangePassword(accessToken, currentPassword, newPassword);

        setChangePasswordBtnLoading(false);

        if (changePasswordResult.hasError) {
            setShowChangePasswordErrorState(changePasswordResult as HttpErrorResult);
        } else {
            setUserId(0);
            setForceChangePasswordState(false);
            await doRevokeCookie();
            const loginRedirectUrlResult = await doGetLoginRedirectUrl(redirectUrl);
            if (loginRedirectUrlResult.hasError) {
                redirectToSignIn(username, redirectUrl);
            } else {
                const loginRedirectUrlResultValue = (loginRedirectUrlResult as HttpObjectResult<LoginRedirectUrlResource>).value;
                redirectToSignIn(username, loginRedirectUrlResultValue.redirectUrl);
            }
        }
    };

    const confirmPasswordValidator = (rule: RuleObject, value?: string) => (confirmPasswordMatch(value) ? Promise.resolve() : Promise.reject(yourPasswordsMustMatch));

    return (
        <ViewModeHandler client={client}>
            <StyledForm form={form} layout={"vertical"} onFinish={onChangePasswordClicked}>
                <Title>{useLocalizedMessage("ChangePassword.Change_password_title")}</Title>
                <StyledSpace direction="vertical" size="large">
                    <ChangePasswordError />
                    <Text>{useLocalizedMessage(forceChangePassword ? "ChangePassword.Your_current_password_is_temporary" : "ChangePassword.Change_password_title")}</Text>
                    <StyledSpace direction="vertical">
                        <Input value={username} name="username" autoComplete="username" style={{ display: "none" }} />
                        <Form.Item name="current_password" label={useLocalizedMessage("ChangePassword.Current_password")}>
                            <Input.Password onChange={(e) => setCurrentPassword(e.target.value)} data-testid="currentPasswordInput" autoComplete="current-password" autoFocus />
                        </Form.Item>
                        <Form.Item name="new_password" label={useLocalizedMessage("ChangePassword.New_password")}>
                            <Input.Password onChange={(e) => setNewPassword(e.target.value)} data-testid="newPasswordInput" autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name="confirm_password" label={useLocalizedMessage("ChangePassword.Confirm_password")} rules={[{ validator: confirmPasswordValidator }]}>
                            <Input.Password onChange={(e) => setConfirmPassword(e.target.value)} data-testid="confirmPasswordInput" autoComplete="new-password" />
                        </Form.Item>
                    </StyledSpace>
                    <Form.Item>
                        <StyledRightAlignCol span={28}>
                            <StyledButton type="default" data-testid="cancelBtn" onClick={onCancelButtonClicked} hidden={isCancelBtnHidden}>
                                {useLocalizedMessage("ChangePassword.Cancel")}
                            </StyledButton>
                            <StyledButton disabled={!isChangePasswordBtnEnabled} loading={changePasswordBtnIsLoading} type="primary" htmlType="submit" data-testid="changePasswordBtn">
                                {useLocalizedMessage("ChangePassword.Change_password_button")}
                            </StyledButton>
                        </StyledRightAlignCol>
                    </Form.Item>
                </StyledSpace>
            </StyledForm>
        </ViewModeHandler>
    );
};

export default ChangePassword;
