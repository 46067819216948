import { atom } from 'recoil';

export const buttonDisabledState = atom({
    key: 'isResetPasswordButtonDisabled',
    default: true
});

export const forgotPasswordState = atom({
    key: 'forgotPasswordInProgress',
    default: false,
});