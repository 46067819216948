import React, { useEffect } from "react";
import { useLocalizedMessage } from "../../hooks";
import { StyledAlert } from "../custom-styled-components";
import { HttpErrorInformation, RequiredValueInformation, HttpErrorResult } from "../../models/http/http";
import { useRecoilState, useRecoilValue } from "recoil";
import { changePasswordAlertMsgState, showChangePasswordErrorState } from "../../shared/state";

export const ChangePasswordError: React.FC = () => {
    const showChangePasswordError = useRecoilValue(showChangePasswordErrorState);
    const [changePasswordAlertMsg, setChangePasswordAlertMsg] = useRecoilState(changePasswordAlertMsgState);
    const genericErrorCode = useLocalizedMessage("ChangePassword.Server_error_alert");
    
    useEffect(() => {
        const errorResult = showChangePasswordError as HttpErrorResult;
        if (errorResult != null) {
            if (errorResult.errorType != null && errorResult.errorType.length > 0) {
                const errorCodes = getErrorCodes(errorResult.errorType);
                setShowErrorMessage(errorCodes);
            } else {
                setShowErrorMessage([genericErrorCode]);
            }
        }
            
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showChangePasswordError]);

    
    const AUTH_EMPTY_USERNAME_EMAIL = "ChangePassword_EmptyUsernameOrEmail";
    const AUTH_EMPTY_CURRENT_PASSWORD_EMAIL = "ChangePassword_EmptyCurrentPassword";
    const AUTH_PASSWORD_CURRENT_EQUIVALENT_ERROR_CODE = "ChangePassword_CurrentAndNewPasswordEquivalent";
    const AUTH_PASSWORD_INCORRECT_ERROR_CODE = "ChangePassword_IncorrectPassword";
    const AUTH_EMPTY_NEW_PASSWORD_ERROR_CODE = "ChangePassword_EmptyNewPassword";
    const AUTH_PASSWORD_TEMP_EQUIVALENT_ERROR_CODE = "ChangePassword_NewAndTempPasswordEquivalent";
    const AUTH_PASSWORD_STRENGTH_ERROR_CODE = "ChangePassword_PasswordStrength";
    const AUTH_PASSWORD_COMPLEXITY_ERROR_CODE =  "ChangePassword_PasswordComplexity";
    const AUTH_PASSWORD_MINIMUM_REVISIONS_ERROR_CODE = "ChangePassword_PasswordMinimumRevisions";

    const emptyUsernameOrEmailLocalized = useLocalizedMessage("ChangePassword.ChangePassword_EmptyUsernameOrEmail");
    const emptyCurrentPasswordLocalized = useLocalizedMessage("ChangePassword.ChangePassword_EmptyCurrentPassword");
    const currentNewAreEquivalentLocalized = useLocalizedMessage("ChangePassword.ChangePassword_CurrentAndNewPasswordEquivalent");
    const incorrectPasswordLocalized = useLocalizedMessage("ChangePassword.ChangePassword_IncorrectPassword")
    const emptyNewPasswordLocalized = useLocalizedMessage("ChangePassword.ChangePassword_EmptyNewPassword");
    const newTempAreEquivalentLocalized = useLocalizedMessage("ChangePassword.ChangePassword_NewAndTempPasswordEquivalent");
    const badPasswordStrength = useLocalizedMessage("ChangePassword.ChangePassword_PasswordStrength");
    const passwordTooSimple = useLocalizedMessage("ChangePassword.ChangePassword_PasswordComplexity");
    const cantBeLastPassword = useLocalizedMessage("ChangePassword.ChangePassword_PasswordMinimumRevisions");
    const cantBeLastNumPasswords = useLocalizedMessage("ChangePassword.ChangePassword_PasswordMinimumRevisions_Multiple");

    const getErrorCodes = (errorInfos: HttpErrorInformation[]): string[] => {
        const result: string[] = [];
        for(const info of errorInfos) {
            switch (info.type){
                case AUTH_EMPTY_USERNAME_EMAIL:
                    result.push(emptyUsernameOrEmailLocalized);
                    break;
                case AUTH_EMPTY_CURRENT_PASSWORD_EMAIL:
                    result.push(emptyCurrentPasswordLocalized);
                    break;
                case AUTH_PASSWORD_CURRENT_EQUIVALENT_ERROR_CODE:
                    result.push(currentNewAreEquivalentLocalized);
                    break;
                case AUTH_PASSWORD_INCORRECT_ERROR_CODE:
                    result.push(incorrectPasswordLocalized);
                    break;
                case AUTH_EMPTY_NEW_PASSWORD_ERROR_CODE:
                    result.push(emptyNewPasswordLocalized);
                    break;
                case AUTH_PASSWORD_TEMP_EQUIVALENT_ERROR_CODE:
                    result.push(newTempAreEquivalentLocalized);
                    break;
                case AUTH_PASSWORD_STRENGTH_ERROR_CODE: {
                    const reqValStrength = (info.information as RequiredValueInformation).requiredValue;
                    result.push(badPasswordStrength.replace("{}", reqValStrength.toString()));
                    break;
                }
                case AUTH_PASSWORD_COMPLEXITY_ERROR_CODE: {
                    const reqValComplexity = (info.information as RequiredValueInformation).requiredValue;
                    result.push(passwordTooSimple.replace("{}", reqValComplexity.toString()));
                    break;
                }
                case AUTH_PASSWORD_MINIMUM_REVISIONS_ERROR_CODE: {
                    const reqValMinRevisions = (info.information as RequiredValueInformation).requiredValue;
                    if (reqValMinRevisions <= 1)  {
                        result.push(cantBeLastPassword);
                    } else {
                        result.push(cantBeLastNumPasswords.replace("{}", reqValMinRevisions.toString()));
                    }
                    break;
                }
            }
        }
        return result;
    }

    const setShowErrorMessage = (details: string[]) => {
        setChangePasswordAlertMsg({
            type: "error",
            message: (
                <>
                    {details.map((x, i) => <li key={i}>{x}</li>)}
                </>
            ),
        });
    };

    return (
        <>
         {changePasswordAlertMsg && <StyledAlert type={changePasswordAlertMsg.type} message={changePasswordAlertMsg.message} data-testid="msgAlert" />}
        </>
    );
};

export default ChangePasswordError;