import React from "react";
import { Card } from "@iqmetrix/antd";

export const BorderLayout: React.FC = (props) => {
    return (
        <Card data-testid = "borderLayout">
            {props.children}
        </Card>
    )
};

export default BorderLayout;