import React from "react";
import { Result } from "@iqmetrix/antd";
import { useLocalizedMessage } from "../../hooks";

export const Error: React.FC = () => {
    const localizedAccessTokenErrorTitle = useLocalizedMessage("Callback.Error_title");
    const localizedAccessTokenErrorResponse = useLocalizedMessage("Callback.No_access_token_was_provided");
    const localizedNonExistentExternalUserErrorTitle = useLocalizedMessage("Error.Non_existent_external_user_error_title");
    const localizedNonExistentExternalUserErrorResponse = useLocalizedMessage("Error.Non_existent_external_user_error_response");
    const localizedUnmatchingExternalUserErrorResponse = useLocalizedMessage("Error.External_user_does_not_match_error_response");
    let localizedErrorTitle = localizedAccessTokenErrorTitle;
    let localizedErrorResponse = localizedAccessTokenErrorResponse;

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("code")) {
        const code = queryParams.get("code") || "";

        switch(code) {
            case 'AuthExternalUserDoesNotExistErrorCode': {
                localizedErrorTitle = localizedNonExistentExternalUserErrorTitle;
                localizedErrorResponse = localizedNonExistentExternalUserErrorResponse;
                break;
            }
            case 'AuthExternalUserDoesNotMatchErrorCode': {
                localizedErrorResponse = localizedUnmatchingExternalUserErrorResponse;
                break;
            }
            default: {
                break;
            }
        }
    }

    return <Result title={localizedErrorTitle} response={{ statusText: localizedErrorResponse }} />;
};

export default Error;
