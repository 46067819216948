import PageLayout from "../page-layout/PageLayout";
import NonBorderLayout from "../page-layout/NonBorderLayout";
import BorderLayout from "../page-layout/BorderLayout";
import EmbeddedLayout from "../page-layout/EmbeddedLayout";
import { ClientType } from "models/ClientType";
import React, { PropsWithChildren } from "react";

export interface ViewModeHandlerProps {
    client: ClientType;
}

export const ViewModeHandler: React.FC<PropsWithChildren<ViewModeHandlerProps>> = ({ client, children }) =>
    client === ClientType.web ? (
        <PageLayout>
            <BorderLayout>{children}</BorderLayout>
        </PageLayout>
    ) : (
        <EmbeddedLayout>
            <NonBorderLayout>{children}</NonBorderLayout>
        </EmbeddedLayout>
    );
