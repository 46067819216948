import { Input, Typography, Form } from "@iqmetrix/antd";
import { StyledForm, StyledButton, StyledSpace, StyledRightAlignCol } from "components/custom-styled-components/CustomStyledComponents";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { usernameState, loginAlertMsgState, clientState } from "shared/state";
import { buttonDisabledState, forgotPasswordState } from "./state";
import { doForgotPassword } from "../../providers/auth-provider";
import { useLocalizedMessage } from "../../hooks";
import { ViewModeHandler } from "../view-mode-handler";

const { Text, Title } = Typography;

export const ForgotPassword: React.FC = () => {
    const [username, setUserInput] = useRecoilState(usernameState);
    const [isButtonDisabled, setIsButtonDisabled] = useRecoilState(buttonDisabledState);
    const history = useHistory();
    const setForgotPasswordAlertMsgState = useSetRecoilState(loginAlertMsgState);
    const [forgotPasswordStatus, setForgotPasswordState] = useRecoilState(forgotPasswordState);
    const client = useRecoilValue(clientState);
    const queryParams = new URLSearchParams(window.location.search);

    const actionCompletedAlertMessage = useLocalizedMessage("ForgotPassword.Alert_message");

    const isValidInput = (input: string) => input && input.length !== 0;

    const inputChanged = (event: any) => {
        const trimmedInput = event.target.value.trim();
        setIsButtonDisabled(!isValidInput(trimmedInput));
        setUserInput(event.target.value);
    };

    const onResetPasswordClick = async () => {
        setForgotPasswordState(true);
        await doForgotPassword(username);

        setForgotPasswordAlertMsgState(actionCompletedAlertMessage);
        setForgotPasswordState(false);
        history.push(`/credentials?${queryParams}`);
    };

    const onCancelButtonClick = () => {
        setForgotPasswordAlertMsgState("");
        history.push(`/credentials?${queryParams}`);
    };

    useEffect(() => {
        if (username) {
            setIsButtonDisabled(false);
        }
    }, [setIsButtonDisabled, username]);

    return (
        <ViewModeHandler client={client}>
            <StyledForm layout={"vertical"}>
                <Title> {useLocalizedMessage("ForgotPassword.Reset_password_Title")} </Title>
                <StyledSpace direction="vertical" size="large">
                    <Text>{useLocalizedMessage("ForgotPassword.Instructions.Please_enter_the_email_address")}</Text>
                    <Text>{useLocalizedMessage("ForgotPassword.Instructions.For_further_assistance")}</Text>
                    <Text>{useLocalizedMessage("ForgotPassword.Email_address")}</Text>
                    <Form.Item>{<Input onChange={inputChanged} value={username} data-testid="usernameInput" name="email" autoComplete="email" autoFocus />}</Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <StyledRightAlignCol span={24}>
                            <StyledButton key="back" onClick={onCancelButtonClick} data-testid="cancelBtn">
                                {useLocalizedMessage("ForgotPassword.Cancel")}
                            </StyledButton>
                            <StyledButton loading={forgotPasswordStatus} disabled={isButtonDisabled} onClick={onResetPasswordClick} type="primary" data-testid="resetPasswordBtn">
                                {useLocalizedMessage("ForgotPassword.Reset_Password_Button")}
                            </StyledButton>
                        </StyledRightAlignCol>
                    </Form.Item>
                </StyledSpace>
            </StyledForm>
        </ViewModeHandler>
    );
};

export default ForgotPassword;
