import React from "react";
import { Col } from "@iqmetrix/antd";

export const SidePane: React.FC = (props) => {
    return (
        <Col xs={1} sm={4} md={6} lg={8} xl={8} >
            { props.children }
        </Col>
    );
};
export default SidePane;