import { atom } from 'recoil';

export const buttonDisabledState = atom({
    key: 'isEnterPasswordButtonDisabled',
    default: true
});

type EnterPasswordStateType = 'none' | 'gettingAuthDetails' | 'error';
export const enterPasswordState = atom<EnterPasswordStateType>({
    key: 'enterPassword',
    default: 'none',
});