const messages = {
    PageTitle: "iQmetrix Sign in",
    CovaPageTitle: "COVA Sign in",

    "Login.Next": "Next",
    "Login.Sign_in_Title": "Sign in",
    "Login.Username_or_Email": "Username or Email",

    "ForgotPassword.Cancel": "Cancel",
    "ForgotPassword.Email_address": "Email address",

    "ForgotPassword.Instructions.Please_enter_the_email_address": "Please enter the email address associated with your account and we'll send you instructions to reset your password.",
    "ForgotPassword.Instructions.For_further_assistance": "For further assistance, please contact your Hub Administrator.",

    "ForgotPassword.Alert_message": "If an account exists, you will receive an email with your temporary password.",
    "ForgotPassword.Reset_Password_Button": "Reset Password",
    "ForgotPassword.Reset_password_Title": "Reset password",

    "Credentials.Error.The_credentials_you_have_entered_are_incorrect": "The credentials you have entered are incorrect.",
    "Credentials.Error.Please_try_again": "Please try again.",
    "Credentials.Error.UserLockedTitle": "Your account is locked",
    "Credentials.Error.User_is_locked": "Please contact your administrator to unlock your account",

    "Credentials.Forgot_password": "Forgot password",
    "Credentials.Password": "Password",
    "Credentials.Sign_in_button": "Sign in",
    "Credentials.Sign_in_title": "Sign in",
    "Credentials.Username_change": "Change",

    "ChangePassword.Cancel": "Cancel",
    "ChangePassword.Change_password_button": "Change Password",
    "ChangePassword.Change_password_title": "Change password",
    "ChangePassword.Confirm_password": "Confirm password",
    "ChangePassword.Current_password": "Current password",
    "ChangePassword.New_password": "New password",
    "ChangePassword.Your_current_password_is_temporary": "Your current password is temporary and must be changed.",
    "ChangePassword.Your_password_was_reset": "Your password was reset. You can sign in using your new password.",
    "ChangePassword.Your_passwords_must_match": "Your passwords must match.",
    "ChangePassword.ChangePassword_IncorrectPassword": "The current password is incorrect.",
    "ChangePassword.ChangePassword_EmptyNewPassword": "The password must not be empty.",
    "ChangePassword.ChangePassword_NewAndTempPasswordEquivalent": "The new password cannot be the same as the temporary password.",
    "ChangePassword.ChangePassword_PasswordStrength": "Your new password must be at least {} characters long.",
    "ChangePassword.ChangePassword_PasswordMinimumRevisions": "Your new password must be different than your previous {} passwords.",
    "ChangePassword.ChangePassword_PasswordMinimumRevisions_Multiple": "Your new password must be different than your previous password.",
    "ChangePassword.ChangePassword_PasswordComplexity": "Your new password must have at least one character from {} of the following: uppercase, lowercase, numbers and special characters.",
    "ChangePassword.ChangePassword_EmptyUsernameOrEmail": "Username Or Email must be provided.",
    "ChangePassword.ChangePassword_EmptyCurrentPassword": "Current password must be provided.",
    "ChangePassword.ChangePassword_CurrentAndNewPasswordEquivalent": "Current and new password must not match.",
    "ChangePassword.Server_error_alert": "There is an error on the server",

    "Callback.Error_title": "There is an error on the server",
    "Callback.No_access_token_was_provided": "No accessToken was provided. Please try again or contact your administrator",
    "Error.Non_existent_external_user_error_title": "Your account is not set up.",
    "Error.Non_existent_external_user_error_response": "Contact your systems administrator to create your account.",
    "Error.External_user_does_not_match_error_response": "Authenticated user does not match initiating user.",

    "Logout.Server_error_alert": "There is an error on the server",
    "Logout.Server_try_again": "Please try again or contact your administrator.",
};

export default messages;
