import { AppError } from "models";
import React from "react";
import { Route } from "react-router-dom";
import { MissingQueryParamMessage } from "shared";

export interface EnforcedQueryParamRouteProps {
    exact?: boolean;
    path: string;
    children: React.ReactNode;
    requiredQueryParams: string[]
}

export const EnforcedQueryParamRoute = ({ children, requiredQueryParams, ...rest}: EnforcedQueryParamRouteProps) => {

    const queryParams = new URLSearchParams(window.location.search);
    for (const requiredQueryParam of requiredQueryParams) {
        if (!queryParams.has(requiredQueryParam)) {
            throw { message: MissingQueryParamMessage } as AppError;
        }
    }

    return (
        <Route {...rest} >
            {children}
        </Route>
    )
}