import { getHubUrl } from "./hub";

const sensitiveQueryParams: string[] = ["accessToken"];

export const redirectExternalUrl = (url: string): void => {
    const reportUrl = new URL(url);
    sensitiveQueryParams.forEach((element) => {
        if (reportUrl.searchParams.has(element)) {
            reportUrl.searchParams.delete(element);
        }
    });

    window.location.assign(url);
};

export const redirectToHubUrl = (): void => {
    const url = window.location.hostname.toLowerCase().includes("cova") ? getHubUrl("covasoft") : getHubUrl("iqmetrix");
    window.location.assign(url);
};
