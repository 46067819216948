import React from "react";
import { Row } from "@iqmetrix/antd";
import CentralPane from "../central-pane/CentralPane";

export const EmbeddedLayout: React.FC = (props) => {
    return (
        <div data-testid="embeddedLayout">
            <Row style={{ minHeight: "90vh" }} justify="center" align="middle" className="center-page-content">
                <CentralPane>{props.children}</CentralPane>
            </Row>
        </div>
    );
};
export default EmbeddedLayout;
