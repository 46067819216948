import React from "react";
import { Row } from "@iqmetrix/antd";
import { SpacingBaseLarge, ColorGrey800, SpacingLayoutXxlarge } from "@iqmetrix/style-tokens";

export const TopBar: React.FC = (props) => {
    return (
        <Row style={{
            paddingLeft: SpacingBaseLarge,
            backgroundColor: ColorGrey800,
            height: SpacingLayoutXxlarge,
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            }}>
            { props.children }
        </Row>
    );
};
export default TopBar;