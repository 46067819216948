const messages = {
    PageTitle: "Connexion iQmetrix",
    CovaPageTitle: "Connexion COVA",

    "Login.Next": "Suivant",
    "Login.Sign_in_Title": "Ouverture de session",
    "Login.Username_or_Email": "Nom d'utilisateur ou courriel",

    "ForgotPassword.Cancel": "Annuler",
    "ForgotPassword.Email_address": "Adresse de courriel",

    "ForgotPassword.Instructions.Please_enter_the_email_address":
        "Veuillez saisir l'adresse de courriel associée à votre compte. Nous vous enverrons ensuite des instructions pour réinitialiser votre mot de passe.",
    "ForgotPassword.Instructions.For_further_assistance": "Pour obtenir de l'aide, veuillez contacter votre administrateur Hub.",

    "ForgotPassword.Alert_message": "Si un compte existe, vous recevrez un courriel comprenant votre mot de passe temporaire.",
    "ForgotPassword.Reset_Password_Button": "Réinitialisez le mot de passe",
    "ForgotPassword.Reset_password_Title": "Réinitialisez le mot de passe",

    "Credentials.Error.The_credentials_you_have_entered_are_incorrect": "Les informations d'identification que vous avez saisies sont incorrectes.",
    "Credentials.Error.Please_try_again": "Veuillez réessayer.",
    "Credentials.Error.UserLockedTitle": "Votre compte est bloqué",
    "Credentials.Error.User_is_locked": "Veuillez contacter votre administrateur pour débloquer votre compte",

    "Credentials.Forgot_password": "Mot de passe oublié",
    "Credentials.Password": "Mot de passe",
    "Credentials.Sign_in_button": "Ouverture de session",
    "Credentials.Sign_in_title": "Ouverture de session",
    "Credentials.Username_change": "Modifiez",

    "ChangePassword.Cancel": "Annuler",
    "ChangePassword.Change_password_button": "Modifiez le mot de passe",
    "ChangePassword.Change_password_title": "Modifiez le mot de passe",
    "ChangePassword.Confirm_password": "Confirmez le mot de passe",
    "ChangePassword.Current_password": "Mot de passe actuel",
    "ChangePassword.New_password": "Nouveau mot de passe",
    "ChangePassword.Your_current_password_is_temporary": "Votre mot de passe actuel est temporaire et doit être modifié.",
    "ChangePassword.Your_password_was_reset": "Votre mot de passe a été réinitialisé. Vous pouvez vous connecter avec votre nouveau mot de passe.",
    "ChangePassword.Your_passwords_must_match": "Vos mots de passe doivent correspondre.",
    "ChangePassword.ChangePassword_IncorrectPassword": "Le mot de passe actuel est incorrect.",
    "ChangePassword.ChangePassword_EmptyNewPassword": "Le mot de passe ne doit pas être vide.",
    "ChangePassword.ChangePassword_NewAndTempPasswordEquivalent": "Le nouveau mot de passe ne peut pas être le même que le mot de passe temporaire.",
    "ChangePassword.ChangePassword_PasswordStrength": "Votre nouveau mot de passe doit comporter au moins {} caractères.",
    "ChangePassword.ChangePassword_PasswordMinimumRevisions": "Votre nouveau mot de passe doit être différent de vos {} mots de passe précédents.",
    "ChangePassword.ChangePassword_PasswordMinimumRevisions_Multiple": "Votre nouveau mot de passe doit être différent de votre ancien mot de passe.",
    "ChangePassword.ChangePassword_PasswordComplexity":
        "Votre nouveau mot de passe doit comporter au moins un caractère {} parmi les suivants : majuscules, minuscules, chiffres et caractères spéciaux.",
    "ChangePassword.ChangePassword_EmptyUsernameOrEmail": "Le nom d'utilisateur ou le courriel doit être fourni.",
    "ChangePassword.ChangePassword_EmptyCurrentPassword": "Le mot de passe actuel doit être fourni.",
    "ChangePassword.ChangePassword_CurrentAndNewPasswordEquivalent": "Le mot de passe actuel et le nouveau ne doivent pas correspondre.",
    "ChangePassword.Server_error_alert": "Il y a une erreur de serveur",

    "Callback.Error_title": "Il y a une erreur de serveur",
    "Callback.No_access_token_was_provided": "Aucun accessToken n'a été fourni. Veuillez réessayer ou contacter votre administrateur",
    "Error.Non_existent_external_user_error_title": "Votre compte n'est pas configuré.",
    "Error.Non_existent_external_user_error_response": "Contactez votre administrateur système pour créer votre compte.",
    "Error.External_user_does_not_match_error_response": "Authenticated user does not match initiating user.",

    "Logout.Server_error_alert": "Il y a une erreur de serveur",
    "Logout.Server_try_again": "Veuillez réessayer ou contacter votre administrateur",
};

export default messages;
