import React from "react";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "@iqmetrix/antd";

import en_US from "@iqmetrix/antd/lib/locale/en_US";
import es_ES from "@iqmetrix/antd/lib/locale/es_ES";
import fr_FR from "@iqmetrix/antd/lib/locale/fr_FR";
import type { Locale } from "@iqmetrix/antd/lib/locale-provider";

import { messages } from "shared";

const getLocale = () => navigator.language.split(/[-_]/)[0];
const getMessages = () => messages[getLocale()];

const getAntdLocale = (): Locale => {
    switch (getLocale()) {
        case "fr":
            return fr_FR;
        case "es":
            return es_ES;
        default:
            return en_US;
    }
};

export const I18nProvider: React.FC = (props) => (
    <ConfigProvider locale={getAntdLocale()}>
        <IntlProvider locale={getLocale()} messages={getMessages()}>
            {props?.children}
        </IntlProvider>
    </ConfigProvider>
);
