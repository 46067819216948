import React from "react";
import { Helmet } from "react-helmet";
import { useLocalizedMessage } from "../../hooks";

export const PageTitle: React.FC = () => {
    const iQmetrixTitle = useLocalizedMessage("PageTitle");
    const covaTitle = useLocalizedMessage("CovaPageTitle");

    return (
        <>
            {window.location.hostname.toLowerCase().includes("cova") ? (
                <Helmet>
                    <title>{covaTitle}</title>
                    <link rel="icon" type="image/x-icon" href="cova_favicon.ico" />
                    <link rel="apple-touch-icon" href="cova_logo192.png" />
                </Helmet>
            ) : (
                <Helmet>
                    <title>{iQmetrixTitle}</title>
                    <link rel="icon" type="image/x-icon" href="favicon.ico" />
                    <link rel="apple-touch-icon" href="logo192.png" />
                </Helmet>
            )}
        </>
    );
};
