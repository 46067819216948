import React from "react";
import { StyledBottomMarginCol } from "components/custom-styled-components";

export const CentralPane: React.FC = (props) => {
    return (
        <StyledBottomMarginCol xs={20} sm={16} md={12} lg={8} xl={6}>
            { props.children }
        </StyledBottomMarginCol>
    );
};
export default CentralPane;