import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

import { RecoilRoot } from "recoil";
import { I18nProvider } from "./components";

import "./index.scss";

async function init() {
    const info = await import("../package.json");
}

ReactDOM.render(
    <RecoilRoot>
        <Sentry.ErrorBoundary>
            <React.StrictMode>
                <I18nProvider>
                    <App />
                </I18nProvider>
            </React.StrictMode>
        </Sentry.ErrorBoundary>
    </RecoilRoot>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
init();
