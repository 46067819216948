import { getUniversalLoginV1Endpoint, getAuthEndpoint } from "shared";

export const getLoginConfigurationUrl = (username : string, clientKey: string, redirectUrl: string) => {
    return `${getUniversalLoginV1Endpoint()}/loginconfiguration?usernameOrEmail=${username}&clientKey=${clientKey}&clientReturnUrl=${redirectUrl}`;
};

export const getCredentialLoginUrl = () => {
    return `${getUniversalLoginV1Endpoint()}/oauth2/token`;
}

export const getChangePasswordUrl = () => {
    return `${getUniversalLoginV1Endpoint()}/account/change-password`;
}

export const getForceChangePasswordUrl = () => {
    return `${getUniversalLoginV1Endpoint()}/account/force-change-password`;
}

export const getForgotPasswordUrl = () => {
    return `${getUniversalLoginV1Endpoint()}/account/forgot-password`;
}

export const getLogoutUrl = (redirectUrl: string) => {
    return `${getUniversalLoginV1Endpoint()}/logout?returnUrl=${redirectUrl}`;
}

export const getAuthRevokeCookieUrl = () => {
    return `${getAuthEndpoint()}/account/revoke-cookie`;
}

export const getLoginRedirectUrl = (returnUrl: string) => {
    return `${getUniversalLoginV1Endpoint()}/login/get-redirect-url?returnUrl=${returnUrl}`;
}