export const getEnvironment = (): string => {
    const host = window.location.host.toLowerCase();
    const parts = /(?:signin)(.*?)\./.exec(host);
    const environmentSuffix = parts && parts[1];
    switch (environmentSuffix) {
        case "int":
        case "rc":
            return environmentSuffix;
        case "":
            return "prod";
        default:
            return "int";
    }
};

export const getEnvironmentSuffix = (): string => {
    const host = window.location.host.toLowerCase();
    const parts = /(?:signin)(.*?)\./.exec(host);
    const environmentSuffix = parts && parts[1];
    switch (environmentSuffix) {
        case "int":
        case "rc":
        case "":
            return environmentSuffix;
        default:
            return "int";
    }
};
