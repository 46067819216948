import React from "react";
import { Alert, Button, Col, Form, Modal, Space } from "@iqmetrix/antd";
import { MarginBaseBase, MarginBaseXsmall, FontSizeBaseDisplayLarge, MarginBaseSmall } from "@iqmetrix/style-tokens";
import styled from "styled-components";

interface IHeaderProps {
    title: string;
}

export const CustomModalHeader: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    return (
        <StyledModalHeader className="ant-modal-header">
            <StyledModalTitle className="ant-modal-title">{props.title}</StyledModalTitle>
        </StyledModalHeader>
    );
};

export const CustomModalFooter: React.FC = ({ children }) => {
    return <StyledModalFooter className="ant-modal-footer">{children}</StyledModalFooter>;
};

export const CustomModalContent: React.FC = ({ children }) => {
    return <StyledModalContent className="ant-modal-body">{children}</StyledModalContent>;
};

export const StyledTitle = styled.h3`
    && {
        font-weight: bold
    }
`;

export const StyledSpace = styled(Space)`
    && {
        display: flex;
    }
`

export const StyledButton = styled(Button)`
    && {
        margin-left: ${MarginBaseSmall};
    }
`;

export const StyledAlert = styled(Alert)`
    && {
        margin-bottom: ${MarginBaseXsmall};
    }
`;

export const StyledChangeButton = styled(Button)`
    && {
        padding: 0;
    }
`;

export const StyledForm = styled(Form)`
    && {
        width: 100%;
        margin-top: ${MarginBaseSmall};

    }
    .ant-form-item {
        margin-bottom: 0px;
    }
`;

export const StyledPasswordFormItem = styled(Form.Item)`
    && {
        margin-top: ${MarginBaseBase};
    }
`;

export const StyledRightAlignCol = styled(Col)`
    && {
        text-align: right;
    }
`;

export const StyledBottomMarginCol = styled(Col)`
    && {
        margin-bottom: ${MarginBaseBase};
    }
`;

export const StyledForgotPasswordButton = styled(Button)`
    && {
        padding: 0;
    }
`;

export const StyledSignInButton = styled(Button)`
    && {
        margin-top: ${MarginBaseXsmall};
        margin-left: auto;
    }
`;

export const StyledInputFormItem = styled(Form.Item)`
    && {
        margin-bottom: 0px;
    }
`;

export const StyledXLBottomMarginInputFormItem = styled(Form.Item)`
    && {
        margin-top: ${MarginBaseBase};
    }
`;
export const StyledModal = styled(Modal)`
    .ant-modal-body:first-child {
        padding: 0;
    }
`;

export const StyledModalHeader = styled.div`
    && {
        border: 1px solid #b7b7b7;
        border-bottom: hidden;
    }
`;

export const StyledModalTitle = styled.div`
    && {
        font-weight: bolder;
        font-size: ${FontSizeBaseDisplayLarge};
    }
`;

export const StyledModalContent = styled.div`
    && {
        border: 1px solid #b7b7b7;
        border-top: hidden;
        border-bottom: hidden;
    }
`;

export const StyledModalFooter = styled.div`
    && {
        border: 1px solid #b7b7b7;
        border-top: hidden;
    }
`;
